<template>
  <div>打印设置</div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    mounted:{

    },
    watch:{

    },
    methods:{

    }
}
</script>

<style>

</style>